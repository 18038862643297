@import 'react-toastify/dist/ReactToastify.css';
@import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
@import 'react-pdf/dist/esm/Page/TextLayer.css';
@import 'react-tooltip/dist/react-tooltip.css';

@import 'react-loading-skeleton/dist/skeleton.css';

@import "swiper/css";
@import "swiper/css/pagination";

body {
  /* Font smoothing */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Background */
  @apply bg-N-25;
  background-image: radial-gradient(circle at 1px 1px, #d0d5dd 1px, transparent 0);
  background-size: 32px 32px;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

table th {
  position: -webkit-sticky; // this is for all Safari (Desktop & iOS), not for Chrome
  position: sticky;
  top: 0;
  z-index: 1; // any positive value, layer order is global
  background: #fff; // any bg-color to overlap
}

* {
  -webkit-tap-highlight-color: transparent;
}

.gm-style-mtc > button {
  font-size: 14px !important;
  line-height: 20px !important;
  height: 38px !important;
  color: #3d3d3c !important
}
.gm-style-mtc > button[aria-checked="true"] {
  font-weight: 700 !important;
}

.ssQIHO-checkbox-menu-item {
  font-size: 14px !important;
  line-height: 20px !important;
}

.pdf_viewer::-webkit-scrollbar {
  width: 8px;               /* width of the entire scrollbar */
}

.pdf_viewer::-webkit-scrollbar-track {
  background: #ededed;        /* color of the tracking area */
}

.pdf_viewer::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 2px solid #ededed;  /* creates padding around scroll thumb */
}

.scrollbar::-webkit-scrollbar {
  width: 8px;               /* width of the entire scrollbar */
}

.scrollbar::-webkit-scrollbar-track {
  background: #F7F7F6;        /* color of the tracking area */
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: #C0C0BF;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
}