@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
}

.mark-down {
    @apply prose-h1:font-display sm:prose-h1:text-display-h1 prose-h1:text-display-h1-m prose-h1:text-N-800 prose-h1:font-500 prose-h1:text-center prose-h1:mb-[36px] prose-h1:mt-[0px]; 
    @apply prose-h2:font-display sm:prose-h2:text-display-h2 prose-h2:text-display-h2-m prose-h2:text-N-800 prose-h2:font-500 prose-h2:text-center prose-h2:mb-[26px] prose-h2:mt-[0px]; 
    @apply lg:prose-h3:text-h2 prose-h3:text-h2-m prose-h3:text-N-800 prose-h3:font-700 prose-h3:mb-[16px] prose-h3:text-center; 
    @apply lg:prose-h4:text-h3 prose-h4:text-h3-m prose-h4:text-N-800 prose-h4:font-700 prose-h4:mb-[12px];
    @apply prose-h5:text-base prose-h5:text-N-700 prose-h5:font-700 prose-h5:mb-[12px];
    @apply prose-h6:text-sm prose-h6:text-N-700 prose-h6:font-700 prose-h5:mb-[12px];
    @apply prose-p:text-base prose-p:text-N-700 prose-p:font-400 prose-p:mt-[0px] prose-p:mb-[12px];
    @apply prose-a:text-B-500;
    @apply prose-table:mb-2;
    @apply hover:prose-tr:bg-N-50 hover:prose-tr:bg-opacity-20 even:prose-tr:bg-N-100 even:prose-tr:bg-opacity-20;
    @apply prose-th:bg-N-50 prose-th:text-left prose-th:text-N-800 prose-th:text-xs prose-th:px-4 prose-th:py-2;
    @apply prose-td:text-N-700 prose-td:text-left prose-td:text-sm prose-td:px-4 prose-td:py-2 prose-td:break-words prose-td:border-b-[1px] prose-td:border-b-N-50;
    @apply prose-a:break-words;
  }

  .prose :where(ul > li):not(:where([class~="not-prose"] *))::marker {
    color: #000000;
}

.prose-blue-bold strong {
  color: #205387;
}

.remove-list-style {
  @apply prose-ul:list-none prose-ul:pl-[6px];
}


.prose-break-link a {
  word-break: break-all !important;
}